<template>
  <validation-provider :rules="rules" :name="name" v-bind="$attrs" v-slot="{ errors, valid, invalid, validated }">
        <a-form-item
        :label="label"
        :rules="rules"
        hasFeedback
        :validateStatus="
            valid && validated && successMessage
            ? 'success'
            : errors[0] && !error
            ? 'error'
            : invalid && validated
            ? 'warning'
            : null
        "
        :help="
            valid && validated && successMessage
            ? successMessage
            : errors[0] && !error
            ? errors[0]
            : null
        "
        >
            <a-range-picker
                v-model="valor"
                type="date"
                :disabled="disabled"
                format="DD/MM/YYYY" 
                v-on="listeners"
                style="width:100%"
                separator="-"
            >
            </a-range-picker>
        </a-form-item>
  </validation-provider>
</template>

<script>
    import Datepicker from '@hokify/vuejs-datepicker'
    import '@hokify/vuejs-datepicker/dist/vuejs-datepicker.css' // include CSS

    export default {
        components: {
            Datepicker
        },
        inheritAttrs: false,
        name: 'base-range-picker',
        props: {
            value: {
                type: Array,
                description: 'Input value'
            },
            label: {
                type: String,
                description: 'Input label (text before input)'
            },
            name: {
                type: String,
                description: 'Input name (used for validation)',
                default: ''
            },
            error: {
                type: String,
                description: 'Input error (below input)'
            },
            successMessage: {
                type: String,
                description: 'Input success message',
                default: ''
            },
            disabled: {
                type: Boolean,
                description: 'Whether input is disabled'
            },
            rules: {
                type: [String, Array, Object],
                description: 'Vee validate validation rules',
                default: ''
            }
        },
        data(){
            return {
                valor: this.value !== null ? this.value : null,
                focused: false 
            }
        },
        computed: {
            listeners() {
                return {
                ...this.$listeners,
                focus: this.onFocus,
                blur: this.onBlur,
                change: this.change
                }
            },
            slotData() {
                return {
                    focused: this.focused,
                    error: this.error,
                    ...this.listeners
                }
            },
        },
        methods:{
            onFocus(evt) {
                this.focused = true
                this.$emit('focus', evt)
            },
            onBlur(evt) {
                this.focused = false
                this.$emit('blur', evt)
            },
            change(newValue){
                this.$emit('input', newValue);
            }
        }
    }
</script>