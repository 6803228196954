var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-provider',_vm._b({attrs:{"rules":_vm.rules,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid, invalid, validated }){return [_c('a-form-item',{attrs:{"label":_vm.label,"rules":_vm.rules,"hasFeedback":"","validateStatus":valid && validated && _vm.successMessage
          ? 'success'
          : errors[0] && !_vm.error
          ? 'error'
          : invalid && validated
          ? 'warning'
          : null,"help":valid && validated && _vm.successMessage
          ? _vm.successMessage
          : errors[0] && !_vm.error
          ? errors[0]
          : null}},[_c('a-range-picker',_vm._g({staticStyle:{"width":"100%"},attrs:{"type":"date","disabled":_vm.disabled,"format":"DD/MM/YYYY","separator":"-"},model:{value:(_vm.valor),callback:function ($$v) {_vm.valor=$$v},expression:"valor"}},_vm.listeners))],1)]}}])},'validation-provider',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }