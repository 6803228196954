
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import AbpBase from '@/lib/abpbase'
import BaseFiltrableDropdown from "@/components/Inputs/BaseFiltrableDropdown.vue"
import BaseRangePicker from "@/components/Inputs/BaseRangePicker.vue"
import PeopleHeader from "@/components/People/people-header.vue";
import { Moment } from "moment"
import Cliente from '@/store/entities/OZONE/cliente'
import Campana from '@/store/entities/OZONE/campana'

@Component({
    components: {
        BaseFiltrableDropdown,
        BaseRangePicker, 
        PeopleHeader
    }
})

export default class FranqueoInformes extends AbpBase{

title:String = "Informes";
disabled: Boolean = false;

get campanas(){
    return null;
}

get clientes(){
    return null;
}

get franqueoTipos(){
    return null;
}

created(){
    this.getPage();
}

getPage(){
    console.log(this.title);
}

close(){
    this.$router.go(-1);
}

formFranqueo: {
    tipoFranqueo: Number;
    date1: [Moment, Moment];
    cliente: Cliente;
    campana: Campana;
  }={
    tipoFranqueo: 0,
    date1: [null, null],
    cliente: null,
    campana: null
  };

  exportRules = {
    franqueo:{
        required: { filtrableDropdownRequired: true }
    },
    campana: {
      required: { filtrableDropdownRequired: true }
    },
    cliente:{
      required: { filtrableDropdownRequired: true }
    },
    fechas: {
      required: { rangePickerRequired: true }
    },
  }
}
